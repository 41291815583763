
import { Instructors } from "@/api";
import { Instructor, InstructorsWithPagination } from "@/api/types";
import { Component, ModelSync, Prop, Vue, Watch } from "vue-property-decorator";
import PageTitle from "../common/PageTitle.vue";

@Component({
  components: { PageTitle }
})
export default class InstructorsList extends Vue {
  @Prop()
  disabled!: boolean;

  headersTitle = [{ icon: "mdi-account-box", header: "Assign Instructors" }];
  instructors: Array<Instructor> = [];
  options = {};
  page = 1;
  limit = 10;
  headers = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Email",
      value: "email"
    },
    {
      text: "Phone",
      value: "phone"
    },
    {
      text: "Actions",
      value: "controls",
      sortable: false,
      width: "50px"
    }
  ];
  instructorsWithPagination: InstructorsWithPagination = {
    meta: {
      perPage: this.limit
    }
  };
  @ModelSync("selected", "change", { type: Array })
  selectedValue!: Array<number>;

  @Watch("options", { deep: true })
  async onOptionsChange(val: any) {
    this.limit = val.itemsPerPage;
    if (this.limit === -1) this.limit = 9999;
    await this.loadInstructorsData(val.page);
  }
  async loadInstructorsData(page = 1) {
    try {
      const instructorsWithPagination = await Instructors.list(
        page,
        this.limit,
        { active: 1 }
      );
      this.instructors = instructorsWithPagination.data
        ? instructorsWithPagination.data
        : [];
      this.instructorsWithPagination = instructorsWithPagination;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  onAssign(id: number) {
    const index = this.selectedValue.findIndex((item: number) => item === id);
    if (index === -1) {
      this.selectedValue.push(id);
    } else {
      this.selectedValue.splice(index, 1);
    }
  }
}
