
import { Courses } from "@/api";
import { Course } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import InstructorsList from "@/components/instructors/InstructorsList.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    InstructorsList,
    PageTitle,
    ValidationProvider,
    ValidationObserver
  }
})
export default class CreateCoursePage extends Vue {
  tableHeader = {
    title: "Assign Instructor",
    icon: "mdi-account"
  };
  headers = [
    { text: "Instructor", value: "instructor", align: "left" },
    { text: "Email", value: "email", align: "center" },
    { text: "Phone number", value: "phoneNumber", align: "center" },
    { text: "Dates available", value: "datesAvailable", align: "center" },
    { text: "Hours", value: "hours", align: "center" }
  ];
  buttonsArray = [
    {
      text: "Back",
      icon: "mdi-chevron-left",
      color: "white",
      action: "back"
    },
    {
      text: "Save",
      icon: "mdi-pencil-box-outline",
      color: "dark",
      action: "save"
    }
  ];
  assessmentsArray = [
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" }
  ];
  headersTitle = [{ icon: "mdi-cast-education", header: "Create course" }];
  selectedInstructors: Array<number> = [];
  course: Course = {
    courseType: "string",
    courseTypeId: "",
    duration: undefined,
    category: { id: 0, title: "" },
    dol: "",
    id: 0,
    instructors: [],
    instructorName: "",
    name: "",
    price: 0,
    status: true,
    nqfLevel: "",
    saqaUnitStandard: "",
    skillsProgramId: "",
    credits: "",
    assessments: []
  };
  durations: {
    id: number;
    name: string;
    day: number;
  }[] = [];
  categories = [];
  types = [];

  get courseId() {
    return this.$route.params.id;
  }

  get isSkillProgramme() {
    return this.course?.category?.id === 9;
  }
  get isOccupationalCertificate() {
    return this.course?.category?.id === 8;
  }

  get outcomeLabel() {
    if (this.isOccupationalCertificate) return "Expert Level Outcome";
    if (this.isSkillProgramme) return "Exit Skills Outcome";
    return "Specific Outcome";
  }

  @Watch("course.category.id")
  handleCategorySelect(val: number) {
    if (val == 8) {
      this.durations.push({
        id: 365,
        name: "365-days",
        day: 365
      });
    } else {
      if (this.durations.length > 5) this.durations.pop();
    }
  }

  async created() {
    try {
      [this.types, this.durations, this.categories] = await Promise.all([
        Courses.types(),
        Courses.durations(),
        Courses.categories()
      ]);
      this.durations = this.durations.slice(0, 5);
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }

  async save() {
    try {
      this.course.assessments = this.assessmentsArray.filter(
        assessment => assessment.title !== ""
      );
      const data = JSON.parse(JSON.stringify(this.course));
      data.instructors = this.selectedInstructors;
      data.categoryId = data.category.id;
      if (data.categoryId === 3) {
        delete data.duration;
      } else {
        data.courseDurationId = data.duration;
        delete data.duration;
      }

      if (this.isSkillProgramme) {
        delete data.saqaUnitStandard;
      } else {
        delete data.skillsProgramId;
      }

      delete data.category;

      await Courses.create(data);
      await this.$success("<strong>Success!</strong> Course has been created!");
      this.course.assessments = [];

      if (this.$route.name === "CreateCourse") {
        await this.$router.push({ name: "CoursesList" });
      }
    } catch (e) {
      const err = e as any;
      this.course.assessments = [];
      (this.$refs.courseForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(err.errors);
      await this.$error(err.errors);
    }
  }
  async onSave() {
    (this.$refs.form as Vue & {
      click: () => void;
    }).click();
  }
  async onGoBack() {
    await this.$router.push({ name: "CoursesList" });
  }
  requireFunc(name: string) {
    return (v: any) => !!v || `${name} is required`;
  }
}
